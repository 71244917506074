<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>{{ title }} Role</h5>
          </div>
          <div class="ibox-content">
            <form-wizard
              title=""
              subtitle=""
              shape="circle"
              color="#1ab394"
              stepSize="md"
              @on-complete="onComplete"
            >
              <tab-content
                title="Role Configuration"
                icon="fa fa-cogs"
                :before-change="() => handleSubmit()"
              >
                <ValidationObserver
                  ref="observer"
                  tag="form"
                  @submit.prevent="handleSubmit()"
                >
                  <div class="row">
                    <div class="form-group col-md-5">
                      <ValidationProvider
                        name="Role Name"
                        :rules="'required|alpha_num'"
                        v-slot="{ errors, classes }"
                      >
                        <label>Name *</label>
                        <input
                          type="text"
                          placeholder="Role Name"
                          v-model="role.name"
                          class="form-control"
                          :class="classes"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-5">
                      <label>Description *</label>
                      <ValidationProvider
                        name="Role Description"
                        :rules="{ required: true }"
                        v-slot="{ errors, classes }"
                      >
                        <input
                          type="text"
                          placeholder="Role Description"
                          v-model="role.description"
                          class="form-control"
                          :class="classes"
                        />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-5">
                      <ValidationProvider
                        name="Service Provider"
                        :rules="{ required: true }"
                        v-slot="{ errors, classes }"
                      >
                        <label>Service Provider *</label>
                        <div :class="classes">
                          <select
                            class="group-select form-control"
                            ref="selectedItem"
                            v-model="role.apiName"
                            id="serviceProvider"
                          >
                            <option></option>
                          </select>
                        </div>
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form-group col-md-5">
                      <ValidationProvider
                        name="Group"
                        :rules="{ is_not: 0 }"
                        v-slot="{ errors, classes }"
                      >
                        <label>Group *</label>
                        <div :class="classes">
                          <select
                            class="group-select form-control"
                            ref="selectedItem"
                            v-model="groupId"
                            id="groupId"
                          >
                            <option></option>
                          </select>
                        </div>
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </ValidationObserver>
              </tab-content>

              <tab-content title="Menu Privilege" icon="fa fa-tasks">
                <div class="panel-body">
                  <div class="panel-group" id="accordion">
                    <div
                      class="panel panel-default"
                      v-for="(menuValue, menuKey) in groupMenu"
                      :key="menuKey"
                      :id="menuKey"
                    >
                      <div class="panel-heading">
                        <h5 class="panel-title">
                          <a
                            data-toggle="collapse"
                            data-parent="#accordion"
                            :href="'#collapse' + menuKey"
                            >{{ getMenuById(menuKey) }}</a
                          >
                        </h5>
                      </div>
                      <div :id="'collapse' + menuKey" class="panel-collapse collapse in">
                        <div class="panel-body">
                          <div class="row">
                            <div
                              class="form-group col-md-2 switch"
                              v-for="(subMenuValue, subMenuKey) in menuValue"
                              :key="subMenuKey"
                            >
                              <label>{{ subMenuValue.displayName }}</label>
                              <div class="onoffswitch">
                                <input
                                  type="checkbox"
                                  class="onoffswitch-checkbox"
                                  :value="subMenuValue.id"
                                  :id="subMenuValue.menu"
                                />
                                <label class="onoffswitch-label" :for="subMenuValue.menu">
                                  <span class="onoffswitch-inner"></span>
                                  <span class="onoffswitch-switch"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </tab-content>
              <tab-content title="API Privilege" icon="fa fa-plug">
                <div class="table-responsive">
                  <table class="table table-bordered table-hover" :class="dataTablesRole">
                    <thead>
                      <tr>
                        <th>{{ apiColumn.action }}</th>
                        <th>{{ apiColumn.id }}</th>
                        <th>{{ apiColumn.name }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="gradeX" v-for="(api, key) in apiDefinition" :key="key">
                        <td style="width: 2%">
                          <input
                            :id="api.id"
                            type="checkbox"
                            name="rowId"
                            :value="api.id"
                            style="margin-left: 30%"
                          />
                        </td>
                        <td style="width: 4%" class="id">{{ api.id }}</td>
                        <td style="width: 40%" class="name">{{ api.text }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th>{{ apiColumn.action }}</th>
                        <th>{{ apiColumn.id }}</th>
                        <th>{{ apiColumn.name }}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import DataTableService from "../plugin/DataTableService";
import Utils from "../plugin/Utils";
import AuditListner from "../plugin/AuditListner";

export default {
  data() {
    return {
      role: {
        name: "",
        description: "",
        apiName: "",
        id: 0,
      },
      groupId: 0,
      title: Constant.data.ADD_TITLE,
      url: Constant.url.ROLE_ADD,
      groupData: [],
      groupMenu: [],
      apiDefinition: [],
      serviceProvider: [],
      dataTablesRole: "dataTables-Role",
      apiColumn: {
        id: "Id",
        name: "API Name",
        action: "#",
      },
      responseData: {},
      selectedMenu: [],
      selectedApi: [],
    };
  },
  created: function () {
    this.loadRoleData();
    this.loadGroupMenu();
    this.loadGroupData();
    this.loadServiceProvider();
  },
  mounted: function () {
    var self = this;
    $("#groupId").change(function () {
      self.groupId = Number($(this).val());
    });
    $("#serviceProvider").change(function () {
      self.role.apiName = $(this).val();
      self.loadApiDefinition($(this).val());
      DataTableService.reLoadDataTable(self.dataTablesRole);
      DataTableService.loadDataTable(self.dataTablesRole, self.exportTitle);
    });
    Utils.initalizeWizard("wizard");
    setTimeout(() => {
      Utils.setSelect2Value("#groupId", self.groupId);
      Utils.setSelect2Value("#serviceProvider", self.role.apiName);
      Utils.selectedMenuChecked(self.responseData.menu);
      Utils.selectedApiChecked(self.dataTablesRole, self.responseData.api);
    }, Constant.timeout);
    DataTableService.loadDataTableWithoutExport(this.dataTablesRole, "", false);
  },
  methods: {
    onSubmit: function () {
      const self = this;
      AxiosService.post(this.url, this.role).then((result) => {
        if (
          Utils.hasValue(result, Constant.data.RESPONSE_CODE) &&
          result.responseCode == Constant.data.SUCCESS
        ) {
          self.$router.replace(Constant.url.SEPARATER + Constant.url.ROLE_URI);
        }
      });
    },
    loadServiceProvider() {
      AxiosService.get(Constant.url.API_DEFINITION_SERVICE_PROVIDER_KEY_VALUE).then(
        (result) => {
          this.serviceProvider = result;
        }
      );
    },
    loadGroupData: function () {
      const self = this;
      AxiosService.get(Constant.url.GROUP_KEY_VALUE).then((result) => {
        self.groupData = result;
      });
    },
    loadGroupMenu: function () {
      const self = this;
      AxiosService.get(Constant.url.GROUP_MENU_ALL).then((result) => {
        self.groupMenu = result;
      });
    },
    loadApiDefinition: function (serviceProvider) {
      if (!Utils.isEmptyStr(serviceProvider)) {
        const self = this;
        AxiosService.get(Constant.url.API_DEFINITION_KEY_VALUE + serviceProvider).then(
          (result) => {
            self.apiDefinition = result;
          }
        );
      }
    },
    handleSubmit: function () {
      return this.$refs.observer.validate();
    },
    getMenuById: function (key) {
      return Utils.getDisplayMenuNameById(key);
    },
    onComplete: function () {
      Utils.clearData(this.selectedApi, this.selectedMenu);
      const self = this;
      $(".onoffswitch-checkbox:checked").each(function () {
        var currentMenuId = Number($(this).attr("value"));
        var roleMenuData = {
          id: 0,
          roleId: self.role.id,
          menuId: currentMenuId,
        };
        self.selectedMenu.push(roleMenuData);
      });

      var data = Utils.tableSelectedCheckedValue(this.dataTablesRole);
      $.each(data, function (index, rowId) {
        var roleApiData = {
          id: 0,
          roleId: self.role.id,
          apiId: $(rowId).find(".id").html(),
        };
        self.selectedApi.push(roleApiData);
      });

      var roleApiRequest = {
        role: this.role,
        roleApiMapping: this.selectedApi,
        roleMenuMapping: this.selectedMenu,
        groupId: this.groupId,
      };

      AxiosService.post(this.url, roleApiRequest).then((result) => {
        if (
          _.has(result, Constant.data.RESPONSE_CODE) &&
          result.responseCode == Constant.data.SUCCESS
        ) {
          Utils.clearData(self.selectedApi, self.selectedMenu);
          self.selectedRoleData();
          AuditListner.addAuditLog(
            AuditListner.initalizedData(
              Constant.data.ROLE_ACTIVITY,
              this.title,
              AuditListner.roleTemplate(
                self.role,
                $("#groupId option:selected").text(),
                self.selectedMenu,
                self.selectedApi
              )
            )
          );
          self.$router.replace(Constant.url.SEPARATER + Constant.url.ROLE_URI);
        }
      });
    },
    selectedRoleData: function () {
      const self = this;
      $(".onoffswitch-checkbox:checked").each(function () {
        self.selectedMenu.push($(this).parent().parent().find("label").text());
      });
      var data = Utils.tableSelectedCheckedValue(this.dataTablesRole);

      $.each(data, function (index, rowId) {
        self.selectedApi.push($(rowId).find(".name").html());
      });
    },
    loadRoleData: async function () {
      var roleId = this.$route.query.id;
      const self = this;
      if (roleId != undefined) {
        AxiosService.get(Constant.url.ROLE_GET + roleId).then((result) => {
          self.role = result[0].role;
          self.responseData = result[0];
          self.groupId = result[0].groupId;
          self.apiDefinition = self.loadApiDefinition(self.role.apiName);
        });
        this.title = Constant.data.UPDATE_TITLE;
        this.url = Constant.url.ROLE_UPDATE;
      }
    },
  },
  watch: {
    groupData() {
      const self = this;
      Utils.initalizedSelect2("#groupId", this.groupData);
    },
    serviceProvider() {
      const self = this;
      Utils.initalizedSelect2("#serviceProvider", this.serviceProvider);
    },
  },
};
</script>
<style scoped>
.panel-default {
  border-color: #8d909285 !important;
}
</style>
